/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import SearchIcon from "assets/img/search-icon.svg";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import {default as SearchIconSVG} from "components/common/svgs/search-icon";
import cookie from "js-cookie";
import Pagination from "../pagination/pagination";
import { StudentAddModal } from "./management-add-modal";
import { StudentDeleteModal } from "./management-delete-modal";
import {
  CleverIdUpdateModal,
  CleverUpdateModal,
  StudentUpdateModal,
} from "./management-update-modal";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  addStudent,
  getStudents,
  deleteStudent,
  updateStudent,
  setFlagForFetch,
  addStudentLicense,
  getSingleSchool,
} from "../../redux/actionCreators/management";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import PermissionHintModal from "./modals/permissionHintModal";
import AddLicenseConfirmModal from "./modals/addLicenseConfirmModal";
import { ReportCardSmall } from "components/common/svgs/report-card";
import ActionType from "redux/actionTypes";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import useSortBy from "hooks/useSortBy";
import { AppCookies } from "util/constants";
import {
  setClassroomSchoolId,
  setClassroomId,
  setDistrictId,
  getStudentDataForReportCard,
  setSubject,
} from "redux/actionCreators/classroom";

import { setFilters } from "redux/actionCreators/activity";
import { getClassroomActivity } from "services/common";
interface RootState {
  management: any;
  auth: any;
  activity: any;
}

const Students: React.FC<{
  page: string;
  districtId?: string;
  schoolId?: string;
  classId?: string;
  onNavigate?: () => void;
}> = ({ page, districtId, schoolId, classId, onNavigate }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, activity } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skeletonCount, setSkeletonCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  const [lastAddedId, setLastAddedId] = useState(0);
  const [openHintModal, setHintModal] = useState(false);
  const [addLicenseModal, setAddLicenseModal] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState(false);
  const { OrderByColumn, sortBy, sortDataBy } = useSortBy("last_name", "ASC");

  const [showCleverModal, setShowCleverModal] = useState(false);

  const userRole = cookie.get(AppCookies.role);

  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      districtId,
      schoolId,
      classId,
      sort_by: sortBy,
      order_by: OrderByColumn,
    };
    dispatch(getStudents(params));
  }, [
    offSet,
    limit,
    management.studentFlag,
    dispatch,
    sortBy,
    OrderByColumn,
    districtId,
    schoolId,
    management.schoolDeleteLoading,
  ]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      let params = {
        offSet: 0,
        limit,
        searchTerm: val,
        districtId,
        schoolId,
        classId,
        sort_by: sortBy,
        order_by: OrderByColumn,
      };
      dispatch(getStudents(params));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (management.lastAddedStudentId > 0) {
      setLastAddedId(management.lastAddedStudentId);
      setSearchTerm("");
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        districtId,
        schoolId,
        classId,
        sort_by: sortBy,
        order_by: OrderByColumn,
        new_student_id: management.lastAddedStudentId,
      };
      dispatch(getStudents(params));
    }
    if (management.studentPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.studentPageNumber);
      setOffSet(management.studentPageNumber * 10 - 10);
      management.studentPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_STUDENT_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedStudentId, management.studentPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.studentsLoading) {
      const element = document.getElementById("student-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.students, management.studentsLoading]);

  const doChangePage = (pageNo: number) => {
    console.log(pageNo, ".....................");
    console.log(
      management?.currentstudentsCount,
      "...........................student........"
    );
    let newOffset = pageNo * limit - limit;
    console.log(newOffset, "OFFSET..........................");
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetUpdateModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const doSetUpdateCleverModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setShowCleverModal(true);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.students.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_STUDENT_FLAG"));
    }
  };

  const onDeletStudent = (type: string) => {
    let data = {
      student_id: currentItem?.id,
      delete_from: type,
    };
    dispatch(deleteStudent(data, setDeleteModal, setPreviousPage));
  };

  const onUpdateStudent = (data: any, type: number = 1) => {
    dispatch(
      updateStudent(
        currentItem.id,
        data,
        type === 2 ? setShowCleverModal : setUpdateModal
      )
    );
  };

  const onAddStudent = (data: any) => {
    console.log(data, "...............................");
    dispatch(
      addStudent(
        {
          ...data,
          district_id: Number(management?.breadcrumbs?.district_id),
          school_id: Number(schoolId),
        },
        setAddModal
      )
    );
    !open && setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);
    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  useEffect(() => {
    if (page === "district" || page === "class") {
      setOpen(true);
      setModalBorder(!modalBorder);
    }
  }, [page]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    // setSkeletonLoad(false);
    // }, 1000);

    if (limit - (currentPage * limit - management.studentsCount) < limit) {
      setSkeletonCount(
        limit - (currentPage * limit - management.studentsCount)
      );
    } else {
      setSkeletonCount(limit);
    }

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.studentsLoading, currentPage]);

  const addLicense = async () => {
    setLicenseLoading(true);
    const data = {
      school_id: Number(schoolId),
      increase_by: 1,
    };
    const res = await addStudentLicense(data);
    if (res?.data?.data?.updated === true) {
      dispatch(getSingleSchool(Number(schoolId)));
      setLicenseLoading(false);
      setAddLicenseModal(false);
      setAddModal(true);
    } else {
      setLicenseLoading(false);
      setAddLicenseModal(false);
    }
  };

  const tableBodayHeight = skeletonCount * 37 + "px";

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (
            e.key === "Enter" &&
            !(
              (e.target as Element).nodeName === "INPUT" ||
              (e.target as Element).nodeName === "BUTTON" ||
              (e.target as Element).nodeName === "A"
            )
          ) {
            setOpen(!open);
          }
        }}
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header ${open ? "open" : ""} ${
          modalBorder ? "closeDelay " : ""
        }`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""} />
          </span>
          <h6>Students</h6>
          {!open ? (
            <div className="accordion__header-search animated ms-3">
              <SearchIconSVG
                title="Search Students"
                style={{ marginRight: "10px" }}
              />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Students"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {
          <div
            className={`accordion__header-text d-none d-lg-block student-instructional-text ${
              !open ? "text-with-searchbar" : ""
            }`}
          >
            {page === "super-admin" ? (
              ""
            ) : (
              <p className="">
                Select a student below to manage and view Report Cards. <br />
                Use the pencil to edit. Select Yes/No to edit Clever ID.
              </p>
            )}
          </div>
        }
        {userRole !== "Classroom Admin" ? (
          <div>
            {page === "district" ? (
              <div className="accordion__header-buttons"></div>
            ) : page === "school" ? (
              <div className="accordion__header-buttons">
                <div className="accordion__header-btnContainer">
                  <button
                    onClick={() => {
                      management.currentSchool?.student_licenses_in_use -
                        management.currentSchool?.student_licenses ===
                      0
                        ? userRole === "Super Admin"
                          ? setAddLicenseModal(true)
                          : setHintModal(true)
                        : setAddModal(true);
                    }}
                    className="btn outline-btn outline-btn-lg"
                  >
                    Add Student
                  </button>
                </div>
              </div>
            ) : (
              <div className="accordion__header-buttons">
                {/* <div className="accordion__header-btnContainer">
                  <a className="btn outline-btn outline-btn-lg" href="/#">
                    Import CSV
                  </a>
                </div> */}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                  <SearchIconSVG
                    fill="#323233"
                    id="searchStudents"
                    title="Search Students"
                  />
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Students"
                />
              </div>
              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={management?.students?.length < 10}
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Students</span>
              </div>
            </div>
            <div className="students-table-container">
              <div className="table-responsive">
                <table className="students__table">
                  <thead>
                    <tr className="students__table-row">
                      <th
                        tabIndex={0}
                        className="students__table-name"
                        onClick={() => {
                          sortDataBy("last_name");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("last_name");
                          }
                        }}
                      >
                        Student Name
                        {sortBy === "last_name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-id"
                        onClick={() => {
                          sortDataBy("school_student_id");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("school_student_id");
                          }
                        }}
                      >
                        Student ID
                        {sortBy === "school_student_id" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-school"
                        onClick={() => {
                          sortDataBy("school_name");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("school_name");
                          }
                        }}
                      >
                        School
                        {sortBy === "school_name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-class"
                        onClick={() => {
                          sortDataBy("classroom_count");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("classroom_count");
                          }
                        }}
                      >
                        Classes
                        {sortBy === "classroom_count" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-last"
                        onClick={() => {
                          sortDataBy("last_login");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("last_login");
                          }
                        }}
                      >
                        Last Login
                        {sortBy === "last_login" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-clever"
                        onClick={() => {
                          sortDataBy("clever_id");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("clever_id");
                          }
                        }}
                      >
                        Has Clever ID
                        {sortBy === "clever_id" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="students__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody style={{ height: tableBodayHeight }}>
                    {/* {skeletonLoad ? (
                      <TableSkeleton
                        columns={7}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : (
                      ""
                    )} */}
                    {management.studentsLoading ? (
                      <TableSkeleton
                        columns={7}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : // && !skeletonLoad
                    management?.students?.length ? (
                      management.students.map((item: any, i: number) => {
                        return (
                          <tr
                            key={i}
                            className={
                              lastAddedId === item.id
                                ? "students__table-row highlight-row"
                                : i % 2 !== 0
                                ? "students__table-row"
                                : "students__table-row"
                            }
                            id={lastAddedId === item.id ? "student-data" : ""}
                          >
                            <td className="name-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  onNavigate && onNavigate();
                                  navigate(`/management/student/${item.id}`);
                                }}
                              >
                                {item?.last_name}, {item?.first_name}
                              </span>
                            </td>
                            <td className="id-data">
                              {item?.school_student_id}
                            </td>
                            <td>
                              <div>{item?.school_name}</div>
                            </td>
                            <td className="text-center">
                              {item?.classroom_count}
                            </td>
                            <td className="text-center">
                              {item?.last_login
                                ? moment(item?.last_login).format("M-D-YY")
                                : "-"}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                fontWeight: item?.clever_id ? "600" : "unset",
                              }}
                            >
                              {item?.clever_id ? (
                                <button
                                  className="students__clever_text_button"
                                  onClick={() => doSetUpdateCleverModal(item)}
                                >
                                  <b>Yes</b>
                                </button>
                              ) : (
                                <button
                                  className="students__clever_text_button"
                                  onClick={() => doSetUpdateCleverModal(item)}
                                >
                                  <b>No</b>
                                </button>
                              )}
                            </td>
                            <td className="options-data">
                              <div className="d-flex align-items-center">
                                <button
                                  tabIndex={0}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      doSetUpdateModal(item);
                                    }
                                  }}
                                  onClick={() => doSetUpdateModal(item)}
                                  className="management__table-editBtn"
                                  style={{ border: "none", background: "none" }}
                                >
                                  <PencilIconManagement fill="#323233" />
                                </button>

                                <span
                                  style={{ marginRight: 20 }}
                                  className="cursor-pointer"
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={async (e: any) => {
                                    if (
                                      e.key === "Enter" &&
                                      item?.classrooms?.length
                                    ) {
                                      dispatch(
                                        setDistrictId(item?.district_id)
                                      );
                                      dispatch(
                                        setClassroomSchoolId(item?.school_id)
                                      );
                                      dispatch(
                                        setClassroomId(item?.classrooms[0])
                                      );
                                      dispatch(
                                        getStudentDataForReportCard({
                                          classroomId: item?.classrooms[0],
                                        })
                                      );

                                      const res = await getClassroomActivity(
                                        item?.school_id
                                      );
                                      if (res?.data?.classrooms?.length) {
                                        const myClass =
                                          res?.data?.classrooms?.find(
                                            (classroom: any) =>
                                              classroom?.classroom_id ===
                                              item?.classrooms[0]
                                          );
                                          const myClass2 =
                                          res?.data?.classrooms?.find(
                                            (classroom: any) =>
                                              classroom?.classroom_id ===
                                              item?.classrooms[1]
                                          );
                                        dispatch(
                                          setSubject(myClass?.fk_subject_id || myClass2?.fk_subject_id)
                                        );

                                        dispatch(setFilters(true));

                                        navigate("/classroom/report-cards", {
                                          state: { student: item },
                                        });
                                        onNavigate && onNavigate();
                                      }
                                    }
                                  }}
                                  onClick={async () => {
                                    if (item?.classrooms?.length) {
                                    

                                      const res = await getClassroomActivity(
                                        item?.school_id
                                      );

                                      if (res?.data?.classrooms?.length) {
                                        const myClass =
                                          res?.data?.classrooms?.find(
                                            (classroom: any) =>
                                              classroom?.classroom_id ===
                                              item?.classrooms[0]
                                          );
                                          const myClass2 =
                                          res?.data?.classrooms?.find(
                                            (classroom: any) =>
                                              classroom?.classroom_id ===
                                              item?.classrooms[1]
                                          );
                                         
                                        dispatch(
                                          setSubject(myClass?.fk_subject_id || myClass2?.fk_subject_id)
                                        );
                                        dispatch(
                                          setDistrictId(item?.district_id)
                                        );
                                        dispatch(
                                          setClassroomSchoolId(item?.school_id)
                                        );
                                        dispatch(
                                          setClassroomId(item?.classrooms[0])
                                        );
                                        dispatch(
                                          getStudentDataForReportCard({
                                            classroomId: item?.classrooms[0],
                                          })
                                        );

                                        dispatch(setFilters(true));

                                        navigate("/classroom/report-cards", {
                                          state: { student: item },
                                        });
                                        onNavigate && onNavigate();
                                      }
                                    }
                                  }}
                                >
                                  <ReportCardSmall />
                                </span>

                                <button
                                  tabIndex={0}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      doSetDelModal(item);
                                    }
                                  }}
                                  onClick={() => doSetDelModal(item)}
                                  className="management__table-editBtn"
                                  style={{ border: "none", background: "none" }}
                                >
                                  <TrashIcon fill="#323233" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management?.students?.length === 0 && (
                          <td colSpan={7} className="no-admin">
                            No Students
                          </td>
                        )}
                      </tr>
                    )}

                    {/* {
                     management.studentsLoading ? 'skeleton' : 'table'
                    } */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.studentsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      <StudentDeleteModal
        loading={management.studentDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name} `}
        bodyText={`Would you like to delete this student entirely from the district?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={(type) => onDeletStudent(type)}
      />
      {updateModal && (
        <StudentUpdateModal
          showSwitch={false}
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem && currentItem.last_name}
          std_id={currentItem && currentItem.school_student_id}
          notes={currentItem && currentItem.notes}
          classroom_id={Number(classId)}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateStudent(data)}
          loading={management.studentUpdateLoading}
          serverError={management.studentUpdateError}
        />
      )}

      {showCleverModal && (
        <CleverIdUpdateModal
          isShow={showCleverModal}
          std_id={currentItem?.id?.toString()}
          clever_id={currentItem?.clever_id}
          onCancel={() => {
            setShowCleverModal(false);
          }}
          onUpdate={(data) => {
            onUpdateStudent(data, 2);
          }}
          loading={false}
        />
      )}

      {addModal && (
        <StudentAddModal
          isShow={addModal}
          loading={management.studentAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data) => onAddStudent(data)}
        />
      )}
      {openHintModal && (
        <PermissionHintModal
          isShow={openHintModal}
          onClose={() => setHintModal(false)}
          onYes={() => setHintModal(false)}
          message={`There are currently no student licenses available for ${management?.currentSchool?.school_name} . Please contact Alpha Plus at (405) 842-8408 to add additional licenses.`}
          customHeader="No Student Licenses Available"
        />
      )}
      {addLicenseModal && (
        <AddLicenseConfirmModal
          isShow={addLicenseModal}
          onClose={() => setAddLicenseModal(false)}
          onYes={addLicense}
          message={`There are currently no student licenses available for this school ${management?.currentSchool?.school_name}. Adding an additional student will automatically increment the license count.`}
          header="No Licenses Available"
          loading={licenseLoading}
        />
      )}
    </>
  );
};

export default Students;
