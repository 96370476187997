import React, { useEffect, useRef, useState } from "react";
import DropdownIndicatorIcon from "./dropdownIndicator";
import classNames from "util/classNames";
import Loader from 'assets/img/giphy.gif';

interface Option {
  label: string;
  value: string;
}

interface ButtonDropDownProps {
  onChange: (value: string) => void;
  options: Option[];
  biggerWidth?: boolean;
  showDropDown?: boolean;
  loadingText?: boolean;
  disabled?: boolean;
}

const ButtonDropDown: React.FC<ButtonDropDownProps> = ({
  onChange,
  options,
  biggerWidth = false,
  showDropDown = true,
  loadingText,
  disabled = false
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const onClickDropdown = () => {
    !loadingText && setOpen(!open);
  };

  const onClickOption = (value: string) => {
    if(loadingText) return;

    setOpen(false);
    onChange(value);
  };

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (menuRef.current?.contains(event.target as Node)) {
        return;
      }
      setOpen(false);
    };

    if (open) {
      document.addEventListener("click", clickHandler, true);
    }

    return () => {
      document.removeEventListener("click", clickHandler, true);
    };
  }, [open]);

  return (
    <div style={{opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? "none" : "auto"}} ref={menuRef}>
      <div
        style={{opacity: loadingText ? 0.5 : 1}}
        className={classNames(
          "reportCards__dropdownButton__dropdown-container",
          showDropDown
            ? ""
            : "reportCards__dropdownButton__dropdown-container-without-dropdown"
        )}
      >
        <span
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            options.length > 0 && onClickOption(options[0].value)
          }}}
          className={`reportCards__dropdownButton_placeholder ${loadingText ? 'loading-reports' : ''}`}
          onClick={() => options.length > 0 && onClickOption(options[0].value)}
        >
          {options.length > 0 ? loadingText ? loadingText : options[0].label : "Select an option"}
          {loadingText && (
            <img src={Loader} width={60} height={60} alt="loader" />
          )}
        </span>
        {showDropDown && (
          <div
            className="verticalBar" 
            onClick={onClickDropdown}
          >
            <div className="reportCards__dropdownButton__divider"></div>
            <span
              role="button"
              tabIndex={0} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickDropdown()
                }
              }} 
              onClick={onClickDropdown}>
              <DropdownIndicatorIcon />
            </span>
          </div>
        )}
        
      </div>
      {open && showDropDown && (
        <div
          className={classNames(
            "reportCards__dropdownButton_menu",
            biggerWidth ? "reportCards__dropdownButton_menu_biggerWidth" : ""
          )}
        >
          <ul className="reportCards__dropdownButton_menu-list">
            {options.map((option, index) => (
              <li
              role="button"
              tabIndex={open && showDropDown ? 0 : -1}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickOption(option.value)
                }
            }}
                key={index}
                onClick={() => onClickOption(option.value)}
                className="listItem"
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ButtonDropDown;
