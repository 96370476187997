import moment from "moment";
import PrintFooter from "components/common/report/Print/footer";
import { browserHeights } from "util/index";
import { ProgressChart } from "./chart";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { Fragment } from "react";
import { SummativeProgressChart } from "./SummativeProgressChart";
import _ from "lodash";
import { BG_COLORS } from "util/constants";

const FormativeProgressChartPrint = ({
  data,
  animations,
  schoolId,
  assessmentType,
  count
}: any) => {
  const activity = useSelector((State: RootState) => State.activity);

  const selectedSchool = activity.schoolsList.find(
    (item: any) => item.id === schoolId
  );

  const getChartData = (data: any) => {
    let labels: string[] = [];

    let A1_Proficiency_Template: any[] = [];
    let A2_Proficiency_Template: any[] = [];
    let B_Proficiency_Template: any[] = [];
    let growth_Template: any[] = [];
    data = _.orderBy(data);
    data?.map((item: any) => {
      const a2Taken = item.summative_test_data.A2_proficient !== null;
      labels.push(item.classroom_name);
      const assessmentData = item.summative_test_data;
      A1_Proficiency_Template.push(assessmentData.A1_proficient);
      A2_Proficiency_Template.push(
        a2Taken ? assessmentData.A2_proficient : null
      );
      B_Proficiency_Template.push(a2Taken ? null : assessmentData.B_proficient);
      growth_Template.push(assessmentData.growth);
      return item;
    });

    return {
      A1_Proficiency_Template,
      A2_Proficiency_Template,
      B_Proficiency_Template,
      growth_Template,
      labels,
    };
  };

  let i = 0;
  let pages: any = [];
  while (i < data?.length) {
    pages.push(data.slice(i, i + 33));
    i += 33;
  }
  return (
    <>
      {pages.map((page: any) => {
        let j = 0;
        let formattedClassrooms: any = [];
        while (j < page?.length) {
          formattedClassrooms.push(page.slice(j, j + 11));
          j += 11;
        }

        const pageChartData = getChartData(page);

        const hasAtleastOneB = !pageChartData.B_Proficiency_Template.every(
          (item: any) => item === null
        );

        const hasAnyMissingA2 = pageChartData.A2_Proficiency_Template.some(
          (item: any) => item === null
        );

        const showBLegend = hasAtleastOneB && hasAnyMissingA2;
        return (
          <div className="school-progress-report-print">
            <div className={`text-white ${browserHeights}`}>
              <div className="print-content">
                <div className="bg-print">
                  <div className="print-report__header d-flex justify-content-between align-items-end">
                    <div className="black-font">
                      <h3 className="title">
                        {assessmentType === "Formative"
                          ? "Formative Progress Chart"
                          : "Summative Progress Chart"}
                      </h3>
                    </div>
                    <div className="black-font">
                      <h3 className="black-font title text-right">
                        {moment(new Date()).format("M-D-YY")}
                      </h3>
                      <p className="black-font print-report__header-text text-right">
                        {selectedSchool?.school_name || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-print">
                  {assessmentType === "Formative" ? (
                    <div className="report__performance mt-4">
                      <div className="d-flex justify-content-center">
                        <div className="formative-test">
                          <div></div>
                          <p>Formative Tests Taken (#)</p>
                        </div>
                        <div className="running-rate">
                          <div></div>
                          <p>Running Proficiency Rate (%)</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="summative-chart-legend-print d-flex justify-content-center">
                      <div className="a1-test">
                        <div style={{backgroundColor:BG_COLORS.BG_A1}}></div>
                        <p>A1 Proficiency (%)</p>
                      </div>
                      {pageChartData.A2_Proficiency_Template.some(
                        (item: any) => item !== null
                      ) && (
                        <div className="a2-test">
                          <div style={{backgroundColor:BG_COLORS.BG_A2}}></div>
                          <p>A2 Proficiency (%)</p>
                        </div>
                      )}
                      {showBLegend && (
                        <div className="b-test">
                          <div style={{backgroundColor:BG_COLORS.BG_B}}></div>
                          <p>B Proficiency (%)</p>
                        </div>
                      )}
                      <div className="growth-test">
                        <div style={{backgroundColor:BG_COLORS.BG_GROWTH}}></div>
                        <p>Growth (%)</p>
                      </div>
                    </div>
                  )}

                  {formattedClassrooms.map((classrooms: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        {assessmentType === "Formative" ? (
                          <>
                          <div className="report__printChart">
                          <div className="report__printChart-container">
                              <ProgressChart
                                data={classrooms}
                                assessmentType={"Formative"}
                                animations={animations}
                              />
                              </div>
                            </div>
                        </>
                      ) : (
                      <>
                          <div className="report__printChartSummative">
                          <div className="report__printChartSummative-containerSummativeChart">
                              <SummativeProgressChart
                                data={classrooms}
                                assessmentType={"Summative"}
                                animations={false}
                                count={count}
                              />
                              </div>
                            </div>
                        </>
                      )}


                        {/* <div className="report__printChart">
                            {assessmentType === "Formative" ? (
                              <div className="report__printChart-container">
                              <ProgressChart
                                data={classrooms}
                                assessmentType={"Formative"}
                                animations={animations}
                              />
                              </div>
                            ) : (
                              <div className="report__printChart-containerSummativeChart">
                              <SummativeProgressChart
                                data={classrooms}
                                assessmentType={"Summative"}
                                animations={false}
                                count={count}
                              />
                              </div>
                            )}
                          
                        </div> */}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            <PrintFooter />
          </div>
        );
      })}
    </>
  );
};
export default FormativeProgressChartPrint;
