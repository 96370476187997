import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  registerables,
  Chart,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BG_COLORS } from "util/constants";

Chart.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  PointElement,
  LineElement
);

const getChartData = (data: any, count: number) => {
  let labels: string[] = [];

  let A1_Proficiency_Template: any[] = [];
  let A2_Proficiency_Template: any[] = [];
  let B_Proficiency_Template: any[] = [];
  let growth_Template: any[] = [];
  data = _.orderBy(data);
  data?.map((item: any) => {
    const a2Taken = item.summative_test_data.A2_proficient !== null;
    labels.push(item.classroom_name);
    const assessmentData = item.summative_test_data;
    A1_Proficiency_Template.push(assessmentData.A1_proficient);
    A2_Proficiency_Template.push(a2Taken ? assessmentData.A2_proficient : null);
    B_Proficiency_Template.push(a2Taken ? null : assessmentData.B_proficient);
    growth_Template.push(assessmentData.growth);
    return item;
  });
  // Pad arrays to reach 11 elements
  
    const targetLength = 11;
    while (labels.length < targetLength) {
      labels.push(""); // Empty string for labels
      A1_Proficiency_Template.push(null);
      A2_Proficiency_Template.push(null);
      B_Proficiency_Template.push(null);
      growth_Template.push(null);
    }
  
  return {
    A1_Proficiency_Template,
    A2_Proficiency_Template,
    B_Proficiency_Template,
    growth_Template,
    labels,
  };
};

export const getOptions = (animations: boolean | undefined) => {
  return {
    animations: animations,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      tooltip: {
        enabled: true,
        events: ['mousemove'],
        filter: function(e: any, legendItem: any) {
          console.log('filter running', e, legendItem);
          return e.label !== "";
        },
        callbacks: {
          label: function (context: any) {
            if (context.raw === null || context.label === "") {
              return null;
            }
            let label =
              context.dataset.label === "a1 Dataset"
                ? "A1 Proficiency"
                : context.dataset.label === "a2 Dataset"
                ? "A2 Proficiency"
                : context.dataset.label === "b Dataset"
                ? "B Proficiency"
                : "Growth";

            if (label) {
              label +=
                ": " +
                context.raw +
                (context.dataset.type === "bar" ? "%" : "");
            }

            return label;
          },
        },
      },
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#323233",
        },
        offset: true,
        skipNull: true,
      },
      y: {
        position: "right",
        ticks: {
          display: false,
          color: "#323233",
        },
        grid: {
          drawOnChartArea: true,
        },
        border: {
          display: true,
        },
      },
      y1: {
        type: "linear",
        position: "left",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          stepSize: 10,
          color: "#323233",
        },
      },
    },
  };
};

export const getData = (
  a1: number[],
  a2: number[],
  b: number[],
  growth: number[],
  labels: string[]
) => {
  return {
    labels,
    datasets: [
      {
        type: "bar",
        label: "a1 Dataset",
        data: a1,
        backgroundColor: BG_COLORS.BG_A1,
        yAxisID: "y1",
        skipNull: true,
        datalabels: {
          align: "end",
          anchor: "end",
          clamp: false,
          color: function (ctx: any) {
            return "#454545";
          },
          font: { size: 14 },
          formatter: function (value: any) {
            return value > 0 ? value + "" : "";
          },
          backgroundColor: function (ctx: any) {
            return "transparent";
          },
        },
      },
      {
        type: "bar",
        label: "a2 Dataset",
        data: a2,
        backgroundColor: BG_COLORS.BG_A2,
        yAxisID: "y1",
        skipNull: true,
        datalabels: {
          align: "end",
          anchor: "end",
          clamp: false,
          color: function (ctx: any) {
            return "#454545";
          },
          font: { size: 14 },
          formatter: function (value: any) {
            return value > 0 ? value + "" : "";
          },
          backgroundColor: function (ctx: any) {
            return "transparent";
          },
        },
      },
      {
        type: "bar",
        label: "b Dataset",
        data: b,
        backgroundColor: BG_COLORS.BG_B,
        yAxisID: "y1",
        skipNull: true,
        datalabels: {
          align: "end",
          anchor: "end",
          clamp: false,
          color: function (ctx: any) {
            return "#454545";
          },
          font: { size: 14 },
          formatter: function (value: any) {
            return value > 0 ? value + "" : "";
          },
          backgroundColor: function (ctx: any) {
            return "transparent";
          },
        },
      },
      {
        type: "bar",
        label: "growth Dataset",
        data: growth,
        backgroundColor: BG_COLORS.BG_GROWTH,
        yAxisID: "y1",
        skipNull: true,
        datalabels: {
          align: "end",
          anchor: "end",
          clamp: false,
          color: function (ctx: any) {
            return "#454545";
          },
          font: { size: 14 },
          formatter: function (value: any) {
            return value > 0 ? value + "" : "";
          },
          backgroundColor: function (ctx: any) {
            return "transparent";
          },
        },
      },
    ],
  };
};

export function SummativeProgressChart(props: any) {
  const { data, animations, count } = props;
  let {
    A1_Proficiency_Template,
    A2_Proficiency_Template,
    B_Proficiency_Template,
    growth_Template,
    labels,
  } = getChartData(data, count);
  const finalData = getData(
    A1_Proficiency_Template,
    A2_Proficiency_Template,
    B_Proficiency_Template,
    growth_Template,
    labels
  );
  const options = getOptions(animations);
  const barThickness = {
    id:"barThickness",
    beforeDatasetsDraw (chart: any){
      
      const {data} = chart;
      data.datasets.forEach((_: any, datasetIndex: number)=>{
        const meta = chart.getDatasetMeta(datasetIndex);
        meta.data.forEach((datapoint: any)=>{return datapoint.width=19})
      })
      
    },
    
  }



  return (
    <div>
      <div>
        <Bar
          options={options as any}
          data={finalData as any}
          className="report__printChart-chart"
          plugins={[barThickness]}
        />
      </div>
    </div>
  );
}
