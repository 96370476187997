import { SchoolProgressSkeleton } from "components/common/skeleton/GraphSkeleton";
import { ProgressChart } from "./chart";
import { SummativeProgressChart } from "./SummativeProgressChart";
import _ from "lodash";
import { BG_COLORS } from "util/constants";

const FormativeProgressChart = ({
  classrooms,
  legendPadding,
  isLoading,
  assessmentType,
  chartData
}: any) => {
  
   const getChartData = (data: any) => {
    let labels: string[] = [];
  
    let A1_Proficiency_Template: any[] = [];
    let A2_Proficiency_Template: any[] = [];
    let B_Proficiency_Template: any[] = [];
    let growth_Template: any[] = [];
    data = _.orderBy(data);
    data?.map((item: any) => {
      const a2Taken = item.summative_test_data.A2_proficient !== null;
      labels.push(item.classroom_name);
      const assessmentData = item.summative_test_data;
      A1_Proficiency_Template.push(assessmentData.A1_proficient);
      A2_Proficiency_Template.push(a2Taken ? assessmentData.A2_proficient : null);
      B_Proficiency_Template.push(a2Taken ? null : assessmentData.B_proficient);
      growth_Template.push(assessmentData.growth);
      return item;
    });
  
    return {
      A1_Proficiency_Template,
      A2_Proficiency_Template,
      B_Proficiency_Template,
      growth_Template,
      labels,
    };
  };


  const data = getChartData(classrooms);
  



  const showBLegend = !data.B_Proficiency_Template.every((item: any) => item === null);
  const showA2Legend = !data.A2_Proficiency_Template.every((item: any) => item === null);


  return (
    <>
      <div className="report hide-on-print">
        <div className="report-content">
          {assessmentType === "Formative" ? (
            <>
              <div
                style={{
                  ...(legendPadding && { paddingBottom: legendPadding }),
                  ...(isLoading && { paddingBottom: 20 }),
                }}
                className="d-flex justify-content-center"
              >
                <div className="formative-test">
                  <div></div>
                  <p>Formative Tests Taken (#)</p>
                </div>
                <div className="running-rate">
                  <div></div>
                  <p>Running Proficiency Rate (%)</p>
                </div>
              </div>

              <div className="report__chart mt-10">
                <div className="report__chart-container">
                  {isLoading ? (
                    <SchoolProgressSkeleton
                      columns={9}
                      width={"100%"}
                      height={484}
                      customClass={"report__chart-skelton-item"}
                    />
                  ) : (
                    <ProgressChart
                      data={classrooms || []}
                      assessmentType={assessmentType}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {classrooms.length > 0 && (
                <div
                  style={{
                    ...(legendPadding && { paddingBottom: legendPadding }),
                    ...(isLoading && { paddingBottom: 20 }),
                  }}
                  className="summative-chart-legend d-flex justify-content-center"
                >
                  <div className="a1-test">
                    <div style={{backgroundColor:BG_COLORS.BG_A1

                    }}></div>
                    <p>A1 Proficiency (%)</p>
                  </div>
                  {showA2Legend && (
                    <div className="a2-test">
                      <div style={{backgroundColor:BG_COLORS.BG_A2}}></div>
                      <p>A2 Proficiency (%)</p>
                    </div>
                  )}
                  {showBLegend && (
                    <div className="b-test">
                      <div style={{backgroundColor:BG_COLORS.BG_B}}></div>
                      <p>B Proficiency (%)</p>
                    </div>
                  )}
                  <div className="growth-test">
                    <div style={{backgroundColor: BG_COLORS.BG_GROWTH}}></div>
                    <p>Growth (%)</p>
                  </div>
                </div>
              )}
              <div className="report__chart mt-10">
                <div className="report__chart-container">
                  {isLoading ? (
                    <SchoolProgressSkeleton
                      columns={9}
                      width={"100%"}
                      height={484}
                      customClass={"report__chart-skelton-item"}
                    />
                  ) : (
                    <SummativeProgressChart
                      data={classrooms || []}
                      assessmentType={assessmentType}
                      count = {chartData?.count}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormativeProgressChart;
