/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button } from "../../common/buttonComponent";
import { validateStudentId, handlePress, handleKeyUp } from "../../../util/index";
import { CustomInput } from "../../common/inputComponent";
import { useSelector,useDispatch } from "react-redux";
import ActionType from "redux/actionTypes";
import CustomModal from "components/common/Modal";

interface MergeStudentModalProps {
  isShow: boolean;
  onClose: () => void;
  onMerge: (x: any) => void;
  onBeforeMerge: (x: any) => void;
  name: string;
  studentId: string;
}
export const MergeStudentModal: React.FC<MergeStudentModalProps> = ({
  isShow,
  onClose,
  onMerge,
  onBeforeMerge,
  name,
  studentId,
}) => {

  const [studentID, setStudentID] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [classes, setClasses] = useState("");
  const [assessments, setAssessments] = useState("");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [conflicts, setConflict] = useState("");
  const [assessmentsAfterMerge, setAssessmentsAfterMerge] = useState("");
  const [classesAfterMerge, setClassesAfterMerge] = useState("");
  const management = useSelector((state: any) => state.management);
  const [clientErrors, setClientErrors] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [studentID]);

  useEffect(() => {
    if(management.mergeStudentData){
      const { info, stats } = management.mergeStudentData;
      setStudentName(info.student_name);
      setClasses(info.classes);
      setAssessments(info.assessments);
      setConflict(info.conflicts);
      setAssessmentsAfterMerge(stats.assessments_after_merge);
      setClassesAfterMerge(stats.classes_after_merge);
      setClientErrors({})
      setLoading(false);
    }
  }, [management.mergeStudentData]);

  useEffect(() => {
    if(management.mergeStudentError){
      setClientErrors({
        schoolStdId: 'Invalid New Student Id',
      });
      setIsSubmitError(true)
      setDisabled(true)
      setStudentName("");
      setClasses("");
      setAssessments("");
      setConflict("");
      setAssessmentsAfterMerge("");
      setClassesAfterMerge("");
      setLoading(false);
    }
  }, [management.mergeStudentError]);

  const checkDisabled = () => {
    if (studentID) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const clearData = () => {
    setStudentID("");
    setStudentName("");
    setClasses("");
    setAssessments("");
    setConflict("");
    setAssessmentsAfterMerge("");
    setClassesAfterMerge("");
    setLoading(false);
    setClientErrors({})
    setIsSubmitError(false);
    dispatch({
      type: ActionType.GET_MERGE_STUDENT_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onClose();
    setShowMessage(false);
  };

  const onSubmit = () => {
    const errors = validateStudentId(studentID.trim());
    const isError = errors.schoolStdId;
    if(isDisabled && !isError && !clientErrors.schoolStdId) {
      return handleCancel();
    }

    if(isError || clientErrors.schoolStdId !== "required"){
      setShowMessage(true);
    }

    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true)
    } else {
      if(studentName){
        let data = {
          old_student_id: studentId,
          new_student_id: studentID,
        };
        onMerge(data);
        handleCancel();
      }
    }
  };

  const handleOnChange = async (name: string, value: string) => {
    dispatch({
      type: ActionType.GET_MERGE_STUDENT_ERROR,
      payload: null,
    });
    name === "schoolStdId" && setStudentID(value);
    const trimmedValue = value.trim();
    const isValid =
      name === "schoolStdId"
        ? validateStudentId(trimmedValue)
        : trimmedValue.length > 0;
    if (isValid.schoolStdId) {
      setClientErrors({
        ...clientErrors,
        [name]: isValid ? isValid.schoolStdId : "required",
      });
      setIsSubmitError(true)
    } else {
      if (value.length >= 4 && value.length <= 10) {
        // setLoading(true);
        const data = {
          old_student_id: studentId,
          new_student_id: value,
        }
        onBeforeMerge(data);
      }
    }
  };

  useEffect(() => {
    isShow && document.addEventListener('keydown', handlePress);
    isShow && document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);
 
  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div className="modal__header">
        <h6 className="modal__header-title">Merge Student</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">
          Enter the Student ID of the student you want to merge with {name},
          Student ID {studentId}.
        </p>
        <p className="modal__body-text">
          The Student you enter below will merge with the ID above and any
          assessment conflicts will be replaced by this {studentId}'s records.
        </p>
        <div className="modal__form">
          <CustomInput
            fieldName="schoolStdId"
            isSubmitError={isSubmitError}
            errorPresentBefore={clientErrors.schoolStdId !== undefined}
            value={studentID}
            type="text"
            autoFocus={true}
            label="Student ID"
            onChange={handleOnChange}
            error={clientErrors.schoolStdId}
            errorMessage={showMessage ? clientErrors.schoolStdId : ""}
            overlayClick={() => {
                setClientErrors({schoolStdId:'required'});
                setShowMessage(false);
              }
            }
          />
          <div className="fieldset">
            <p className="fieldset-legend">Student Merge Info</p>
            <div className="fieldset-info">
              {studentID !== "" ? (
                <>
                  <div className="mx-3 my-3">
                    <p>Student Name: {studentName}</p>
                    <p>Classes: {classes}</p>
                    <p>Assessments: {assessments}</p>
                    <p>Conflicts: {conflicts}</p>
                  </div>
                </>
              ) : (
                <div className="fieldset-empty">
                  <p className="text-muted lead">
                    Enter Student ID to merge with above.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="fieldset-stats mt-5">
            <p>Total Assessments After Merge: {assessmentsAfterMerge}</p>
            <p>Total Classes After Merge: {classesAfterMerge}</p>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={handleCancel}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Merge"
          onClick={onSubmit}
          // disabled={isDisabled}
          className={`btn success-btn btn-lg ${(isDisabled || studentName.trim() === '' )&& 'btn-disabled'}`}
          type="button"
          id="save-button"
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};
