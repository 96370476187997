import React from "react"
import NumberedLabel from "./numberedLabel";

interface IBooks {
    bookNo: number;
    title: string;
    onClickTeacherPdf: () => void;
    onClickStudentPdf: () => void;
    teacherDisabled: boolean;
    studentDisabled: boolean;

}

const Book = ({
    bookNo, 
    title, 
    onClickStudentPdf, 
    onClickTeacherPdf,
    teacherDisabled=false,
    studentDisabled=false,
}: IBooks) => {
    return (
        <span className={`book-box ${(teacherDisabled && studentDisabled)  ? 'disable' : ''}`}>
            <NumberedLabel 
                number={bookNo}
            />
            <span className="book-detail">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="32" viewBox="0 0 17 32" fill="none">
                <g clip-path="url(#clip0_504_1427)">
                    <path d="M10.9149 0.426934C10.9149 0.426934 10.6803 0.212676 10.6072 0.114655C10.5349 0.0166336 10.2994 0.064343 10.2994 0.064343L0.59755 5.7808C0.26775 5.9751 0 6.44786 0 6.83648V15.4112L0.6154 15.7738L16.4237 5.77993V3.67291C13.3688 4.08148 10.9149 0.426934 10.9149 0.426934Z" fill="#B61A27"/>
                    <path d="M0 15.411V31.202C0 31.3945 0.06545 31.5307 0.17255 31.5949C0.2805 31.66 0.8058 31.9662 0.8058 31.9662L15.8253 22.6264C16.1551 22.4321 16.4228 21.9593 16.4228 21.5707V5.77979L0.6154 15.7736L0 15.411Z" fill="#DEDEDE"/>
                    <path d="M10.9155 0.426758L1.21278 6.14321C0.882984 6.33752 0.615234 6.81028 0.615234 7.19889V15.7736L16.9998 6.11979V4.01277C13.5658 4.47252 11.0651 1.22914 10.9147 0.426758H10.9155Z" fill="#ED1A27"/>
                    <path d="M0.615234 31.5644C0.615234 31.953 0.882984 32.1108 1.21278 31.9165L16.4031 22.9663C16.7329 22.7719 17.0007 22.2992 17.0007 21.9106V6.11963L0.615234 15.7734V31.5644Z" fill="#F6F6F6"/>
                    <path d="M5.21875 29.5565L16.4039 22.9665C16.7337 22.7722 17.0014 22.2994 17.0014 21.9108V6.13721C16.4362 7.11742 17.5726 20.012 5.2196 29.5565H5.21875Z" fill="#F0F0F0"/>
                    <path d="M9.93082 7.12525C9.82712 8.31538 10.366 8.19047 11.001 7.8513L17.0003 4.0224L10.4391 0.707031L9.93082 7.12612V7.12525Z" fill="#CF1A27"/>
                    <path d="M16.4221 4.35176C16.6176 4.19736 16.9992 4.01173 16.9992 4.01173C16.9159 3.95621 10.7109 0.0214844 10.7109 0.0214844C11.1563 2.93176 16.2266 4.50617 16.4221 4.35176Z" fill="#F32733"/>
                    <path d="M10.4387 0.706942L10.3809 6.70445C10.3987 7.31947 11.2479 7.32987 11.2479 7.32987L16.5663 3.78376L10.9147 0.426758L10.4395 0.706942H10.4387Z" fill="#B61A27"/>
                    <path d="M10.9141 6.89442C10.9141 7.28303 11.1818 7.44091 11.5116 7.2466L17.0001 4.01277L10.9141 0.426758V6.89442Z" fill="#F32733"/>
                    <path d="M10.2997 0.0644208C10.6184 0.0887092 10.4391 0.707197 10.4391 0.707197L10.9142 0.427012C10.9142 -0.226173 10.2988 0.0644208 10.2988 0.0644208H10.2997Z" fill="#B61A27"/>
                    <path d="M2.45508 24.2917L15.2986 16.7233V16.313L2.45508 23.8806V24.2917Z" fill="#454545"/>
                    <path d="M2.45508 26.4172L15.2986 18.8496V18.4385L2.45508 26.0069V26.4172Z" fill="#454545"/>
                    <path d="M2.45508 28.5423L15.2986 20.9748V20.5645L2.45508 28.132V28.5423Z" fill="#454545"/>
                    <path d="M2.45312 22.1148L7.99427 18.8498V18.4395L2.45312 21.7036V22.1148Z" fill="#454545"/>
                    <path d="M2.45312 19.9903L7.99427 16.7252V16.3149L2.45312 19.5791V19.9903Z" fill="#454545"/>
                    <path d="M8.49414 19.2692L15.2924 15.2633V9.97363L8.49414 13.9786V19.2692Z" fill="#31AEFF"/>
                    <path d="M11.8915 15.4544L15.0739 13.5789C15.0739 13.5789 13.6663 12.9032 13.3416 12.8902C13.0169 12.8772 12.0861 14.9825 11.8906 15.4544H11.8915Z" fill="#425056"/>
                    <path d="M10.528 13.7326C10.3979 13.6303 9.07703 16.5093 8.70898 17.5936C8.70898 17.5936 8.77358 17.7767 9.96443 17.0497C11.1553 16.3228 12.2594 15.2385 12.2594 15.2385C12.2594 15.2385 11.0473 13.9374 10.5271 13.7335L10.528 13.7326Z" fill="#556267"/>
                    <path d="M8.49414 17.585V19.2687L15.2924 15.2629V13.4517C15.2924 13.4517 13.3867 14.0641 11.9366 15.301C10.4857 16.538 9.59829 17.317 8.49414 17.5842V17.585Z" fill="#31C23D"/>
                    <path d="M14.347 10.974C14.0767 11.1336 13.8574 11.5214 13.8574 11.8397C13.8574 12.1581 14.0767 12.2882 14.347 12.1286C14.6173 11.969 14.8366 11.5812 14.8366 11.2629C14.8366 10.9445 14.6173 10.8144 14.347 10.974Z" fill="#FFE000"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.38162 8.42124C2.74712 8.20611 2.98597 8.08294 3.09647 8.05257C3.22482 8.01701 3.33702 8.02482 3.43392 8.07686C3.46622 8.09421 3.76117 8.26944 3.76117 8.26944C3.76117 8.26944 3.66512 9.14208 3.59882 9.32772C3.53167 9.51422 3.44752 9.67903 3.34552 9.82303C3.24352 9.96789 3.13982 10.0841 3.03357 10.1735C2.88992 10.2914 2.68167 10.4311 2.40967 10.5916L2.01697 10.8223L1.73902 12.6917L1.41602 12.5008V8.99028L2.38162 8.42124ZM2.82192 9.69638C2.90182 9.61311 2.96472 9.51855 3.00977 9.41359L3.00892 9.41446C3.05482 9.3095 3.07692 9.20541 3.07692 9.10131C3.07692 8.97293 3.04547 8.88706 2.98172 8.84108C2.91882 8.79511 2.83807 8.79077 2.74032 8.82807C2.66892 8.85409 2.52442 8.9313 2.30767 9.05881L2.01697 9.2297V10.2255L2.34592 10.0312C2.58307 9.89156 2.74202 9.77965 2.82192 9.69638Z" fill="#D7D7D7"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.85741 6.4398C6.03166 6.39816 6.18126 6.41725 6.30621 6.49879H6.30706C6.43116 6.58119 6.63006 6.68963 6.63006 6.68963C6.63006 6.68963 6.68871 7.34368 6.68871 7.67244C6.68871 7.9613 6.65726 8.2276 6.59691 8.47309C6.52296 8.77323 6.41671 9.04213 6.27816 9.28155C6.17361 9.46371 6.03251 9.6398 5.85486 9.81156C5.72226 9.93907 5.54546 10.0683 5.32276 10.1993L4.51441 11.0563L4.19141 10.8655V7.35495L5.29046 6.70697C5.53866 6.56124 5.72736 6.4719 5.85741 6.4398ZM5.84806 9.1176C5.91181 9.01177 5.96451 8.86691 6.00531 8.68648H6.00616C6.04696 8.50518 6.06736 8.27878 6.06736 8.00727C6.06736 7.73576 6.04696 7.53885 6.00616 7.41828C5.96536 7.29684 5.90841 7.21703 5.83531 7.17886C5.76136 7.13983 5.66956 7.13983 5.55651 7.17713C5.47236 7.20489 5.30746 7.2899 5.06266 7.43476L4.79236 7.5935V9.91912L5.24116 9.65455C5.40946 9.55566 5.53101 9.47325 5.60496 9.40646C5.70271 9.32058 5.78346 9.2243 5.84806 9.1176Z" fill="#D7D7D7"/>
                    <path d="M9.56757 4.56779L9.24372 4.37695L7.20117 5.58096V9.09151L7.52502 9.28148L7.80297 7.24559L9.37037 6.10924L9.04652 5.9184L7.80297 6.65139V5.82038L9.56757 4.56779Z" fill="#D7D7D7"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.70583 8.61123C3.07133 8.39611 3.31018 8.27293 3.42068 8.24257H3.42153C3.59238 8.19486 3.73518 8.22522 3.85078 8.33365C3.96553 8.44295 4.02333 8.63639 4.02333 8.91571C4.02333 9.13083 3.99018 9.33208 3.92388 9.51771C3.85673 9.70421 3.77258 9.86903 3.67058 10.013C3.56858 10.1579 3.46488 10.2741 3.35863 10.3635C3.21498 10.4814 3.00673 10.6211 2.73473 10.7816L2.34203 11.0123V12.336L1.74023 12.6908V9.18028L2.70583 8.61123ZM3.14613 9.88638C3.22603 9.8031 3.28893 9.70855 3.33398 9.60359L3.33313 9.60446C3.37903 9.4995 3.40113 9.3954 3.40113 9.29131C3.40113 9.16293 3.36968 9.07705 3.30593 9.03108C3.24218 8.9851 3.16143 8.98076 3.06368 9.01806C2.99228 9.04409 2.84778 9.12129 2.63103 9.24881L2.34033 9.41969V10.4155L2.67013 10.2212C2.90728 10.0816 3.06623 9.96965 3.14613 9.88638Z" fill="#FAFAFA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.17967 6.63072C6.35392 6.58908 6.50352 6.60817 6.62847 6.68971L6.62762 6.68884C6.75172 6.77124 6.84692 6.90483 6.91237 7.09046C6.97782 7.2761 7.01012 7.53373 7.01012 7.86249C7.01012 8.15135 6.97952 8.41765 6.91917 8.66314C6.84522 8.96328 6.73897 9.23218 6.60042 9.4716C6.49587 9.65376 6.35477 9.82985 6.17712 10.0016C6.04452 10.1291 5.86772 10.2584 5.64502 10.3894L4.51367 11.0564V7.54587L5.61272 6.89789C5.86092 6.75216 6.04962 6.66281 6.17967 6.63072ZM6.17032 9.30852C6.23407 9.20269 6.28677 9.05783 6.32757 8.8774C6.36837 8.6961 6.38877 8.4697 6.38877 8.19819C6.38877 7.92668 6.36837 7.72977 6.32757 7.6092C6.28677 7.48775 6.22982 7.40795 6.15672 7.36978C6.08362 7.33075 5.99097 7.33075 5.87877 7.36805C5.79462 7.3958 5.62972 7.48081 5.38492 7.62568L5.11462 7.78442V10.11L5.56342 9.84547C5.73172 9.74658 5.85327 9.66417 5.92722 9.59738C6.02497 9.5115 6.10572 9.41521 6.17032 9.30852Z" fill="#FAFAFA"/>
                    <path d="M7.52539 9.28156L8.12634 8.92764V7.43564L9.37074 6.70265V6.10932L8.12634 6.84144V6.01043L9.56794 5.16207V4.56787L7.52539 5.77102V9.28156Z" fill="#FAFAFA"/>
                </g>
                <defs>
                    <clipPath id="clip0_504_1427">
                    <rect width="17" height="32" fill="white"/>
                    </clipPath>
                </defs>
                </svg>
                <span className="book-name">{title}</span>
            </span>
            <span className="pdf-btns-group">
                <button 
                    disabled={teacherDisabled } 
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickTeacherPdf();
                    }} 
                    type="button" 
                    className="button-green"
                    tabIndex={teacherDisabled ? -1 : 0}
                    style={{opacity: teacherDisabled && !studentDisabled ? 0.5 : 1}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                    <g clip-path="url(#clip0_4789_1425)">
                    <path d="M12.6225 15.0009H3.37777C1.5152 15.0009 0 13.5362 0 11.7358C0 11.3252 0.34446 10.9922 0.769266 10.9922C1.19407 10.9922 1.53853 11.3252 1.53853 11.7358C1.53853 12.716 2.36349 13.5137 3.37777 13.5137H12.6225C13.6365 13.5137 14.4617 12.7162 14.4617 11.7358C14.4617 11.3252 14.8062 10.9922 15.231 10.9922C15.6558 10.9922 16.0003 11.3252 16.0003 11.7358C16.0003 13.5362 14.4851 15.0009 12.6225 15.0009Z" fill="#323233"/>
                    <path d="M7.99974 0C8.42454 0 8.769 0.332969 8.769 0.743605V10.7885C8.769 11.1992 8.42454 11.5322 7.99974 11.5322C7.57493 11.5322 7.23047 11.1992 7.23047 10.7885V0.743605C7.23047 0.332969 7.57493 0 7.99974 0Z" fill="#323233"/>
                    <path d="M7.99988 11.5329C7.79596 11.5329 7.60026 11.4545 7.45601 11.3151L3.19017 7.19152C2.88973 6.9011 2.88973 6.43023 3.19017 6.14006C3.49062 5.84965 3.97774 5.84965 4.27818 6.14006L7.99988 9.73787L11.7566 6.10649C12.057 5.81607 12.5441 5.81607 12.8446 6.10649C13.145 6.3969 13.145 6.86778 12.8446 7.15794L8.54375 11.3153C8.3995 11.4547 8.2038 11.5332 7.99988 11.5332V11.5329Z" fill="#323233"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4789_1425">
                    <rect width="16" height="15" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                    <span>Teacher PDF</span>
                </button>
                <button 
                    disabled={studentDisabled } 
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickStudentPdf();
                    }} 
                    type="button" 
                    className="button-green"
                    tabIndex={studentDisabled ? -1 : 0}
                     style={{opacity: studentDisabled && !teacherDisabled ? 0.5 : 1}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                    <g clip-path="url(#clip0_4789_1425)">
                    <path d="M12.6225 15.0009H3.37777C1.5152 15.0009 0 13.5362 0 11.7358C0 11.3252 0.34446 10.9922 0.769266 10.9922C1.19407 10.9922 1.53853 11.3252 1.53853 11.7358C1.53853 12.716 2.36349 13.5137 3.37777 13.5137H12.6225C13.6365 13.5137 14.4617 12.7162 14.4617 11.7358C14.4617 11.3252 14.8062 10.9922 15.231 10.9922C15.6558 10.9922 16.0003 11.3252 16.0003 11.7358C16.0003 13.5362 14.4851 15.0009 12.6225 15.0009Z" fill="#323233"/>
                    <path d="M7.99974 0C8.42454 0 8.769 0.332969 8.769 0.743605V10.7885C8.769 11.1992 8.42454 11.5322 7.99974 11.5322C7.57493 11.5322 7.23047 11.1992 7.23047 10.7885V0.743605C7.23047 0.332969 7.57493 0 7.99974 0Z" fill="#323233"/>
                    <path d="M7.99988 11.5329C7.79596 11.5329 7.60026 11.4545 7.45601 11.3151L3.19017 7.19152C2.88973 6.9011 2.88973 6.43023 3.19017 6.14006C3.49062 5.84965 3.97774 5.84965 4.27818 6.14006L7.99988 9.73787L11.7566 6.10649C12.057 5.81607 12.5441 5.81607 12.8446 6.10649C13.145 6.3969 13.145 6.86778 12.8446 7.15794L8.54375 11.3153C8.3995 11.4547 8.2038 11.5332 7.99988 11.5332V11.5329Z" fill="#323233"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4789_1425">
                    <rect width="16" height="15" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                    <span>Student PDF</span>
                </button>
            </span>
        </span>
    )
};

export default Book;
