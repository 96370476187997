/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Performance from "./Performance";
import {
  getSingleStudentData,
  getSingleStudentSBRData,
  getStandardBaseReport,
  getStudentDataForReportCard,
  getStudentReportCardData,
  getStandardBaseReportAll
} from "redux/actionCreators/classroom";
import { orderBy } from "lodash";
import filterAndReorderClassrooms from "./types/classroomObjectsFilter";
import StudentPerformancePrint from "./print/StudentPerformancePrint";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import BasedReportPrint from "../../components/StandardReports/Based/Print";
import DownloadPdf from "../../components/StandardReports/Based/Download";
import StudentHeader from "components/common/StudentDetailHeader";
import StandardBasedReport from "components/common/StandardBased";
import { useLocation } from "react-router-dom";
import { useSchoolName } from "hooks/useSchoolName";
import { performanceOptions } from "./types/type";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import StandardBasedDownload from "./Download/StandardBased";
import StudentPerformanceDownload from "./Download/PerformaceBased";
import {
  setClassroomSchoolId,
  setClassroomId,
  setDistrictId,
} from "redux/actionCreators/classroom";

import { setFilters } from "redux/actionCreators/activity";
interface RootState {
  auth: any;
  studentReport: any;
  classroom: {
    studentsReportData: any;
    classroomSchoolId: string;
    classroomId: string;
    classrooms: any;
    studentReport: {
      data: {
        students_mapped: any;
        performance_level: {};
        performance: any;
        students: any;
        variants: string[]
      };
    };
    studentDetailData: {
      data: { [key: string]: any };
    };
    studentSBRDetailData: any;
    subject: number;
  };
}

export enum REPORT_OPTIONS  {
  STANDARD_BASED, 
  PERFORMANCE_BASED
}

const ReportCard: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [allStudentsPerformanceData, setAllStudentsPerformanceData] = useState<
    any[]
  >([]);
  const [selectedPerformance, setSelectedPerformance] = useState<any>(
    performanceOptions[0]
  );
  const [allStudentsSBRData, setAllStudentsSBRData] = useState<any>([]);
  const [standardBasedDataAll, setStandardBasedDataAll] = useState<any>([]);
  const [printingPdf, setPrintingPdf] = useState(false);

  const [performanceData, setPerformanceData] = useState<any>({});
  const [classroomStudentData, setClassroomStudentData] = useState<any[]>([]);
  const [studentData, setStudentData] = useState<any>({});
  const [studentSBRData, setStudentSBRData] = useState<any>([]);
  const [classrooms, setClassrooms] = useState<any>({});
  const [sortInfo, setSortInfo] = useState<any>([]);
  const [isPerformance, setIsPerformance] = useState(true);
  const [performanceProgress, setPerformanceProgress] = useState<any>();
  const [studentSelected, setStudentSelected] = useState(0);
  const [yearSelected, setYearSelected] = useState(1);
  const [descriptionRowsHeights, setDescriptionRowsHeights] = useState<any>([]);
  const [skeletonLoad, setSkeletonLoad] = useState(false);
  const [previousYearClassroom, setPreviousYearClassroom] = useState(0);
  const [previousYearStudent, setPreviousYearStudent] = useState(0);
  const [showLastThreeAttempts, setShowLastThreeAttempts] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [showAllClasses, setShowAllClasses] = useState(
    location.state?.student ? true : false
  );
  const componentPerformancePrintRef = useRef(null);
  const componentPerformancePrintAllRef = useRef(null);
  const componentSBRPrintRef = useRef(null);
  const componentSBRPrintAllRef = useRef(null);
  const [allStudentPerformanceVariants, setAllStudentPerformanceVariants] = useState<string[]>([]);
  const [allStudentsScienceEnabled, setAllStudentsScienceEnabled] = useState<boolean>(false);
  

  const detailsRef = useRef<any>(null);
  const app = useSelector((state: RootState) => state);

  const school_name = useSchoolName();

  const studentLastNameRef = useRef(null);

  const handlePerformancePrint = useReactToPrint({
    content: () => componentPerformancePrintRef.current,
    documentTitle: `PerformanceReport_${studentLastNameRef.current}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handlePerformancePrintAll = useReactToPrint({
    content: () => componentPerformancePrintAllRef.current,
    documentTitle: `PerformanceReport_${moment(new Date()).format(
      "M/DD/YY hh:mmA"
    )}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handleSBRPrint = useReactToPrint({
    content: () => componentSBRPrintRef.current,
    documentTitle: `StandardBasedReport_${
      studentLastNameRef.current ? studentLastNameRef.current : "student"
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handleSBRPrintAll = useReactToPrint({
    content: () => componentSBRPrintAllRef.current,
    documentTitle: `StandardBasedReport_${moment(new Date()).format(
      "M/DD/YY hh:mmA"
    )}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });


  const printAllSBRFinal =async (isPrint: boolean)=>{
    await  fetchAllSBRFinal();
    isPrint && handleSBRPrintAll();

  }

  const handlePrintClick = (option: string, type: number) => {
    if (type === REPORT_OPTIONS.PERFORMANCE_BASED && option === "print") handlePerformancePrint();
    else if (type === REPORT_OPTIONS.STANDARD_BASED && option === "print") handleSBRPrint();
    else if (type === REPORT_OPTIONS.PERFORMANCE_BASED && option === "print_all") handlePerformancePrintAll();
    else if (type === REPORT_OPTIONS.STANDARD_BASED && option === "print_all") printAllSBRFinal(true);

  };

  const handleSortClick = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );
    if (info) {
      const updatedData = sortInfo.map((data: any) => {
        if (data === info) {
          data.orderBy = info.orderBy === "ASC" ? "DESC" : "ASC";
        }
        return data;
      });
      setSortInfo([...updatedData]);
    } else {
      sortData.orderBy = "DESC";
      setSortInfo([...sortInfo, sortData]);
    }
  };

  const fetchAllSBRData = async () => {
    const checkClassroomId =
      yearSelected === 2
        ? +previousYearClassroom
        : +app?.classroom?.classroomId;

    if (checkClassroomId) {
      const response: any = await getStandardBaseReport(checkClassroomId);
     
      setAllStudentsSBRData(response?.data?.data);
    }
  };

  const  fetchAllSBRFinal = async()=>{
    const checkClassroomId =
    yearSelected === 2
      ? +previousYearClassroom
      : +app?.classroom?.classroomId;

  if (checkClassroomId) {
    const response: any = await getStandardBaseReportAll(checkClassroomId);
    const data = response?.data?.data;
    const showScience = data?.science_enabled;
    const updatedData = data.classrooms.map((classroom: any)=>{
        const studentsWithClassrooms = classroom.students.map((student: any)=>{
          return {
            ...student, 
            classroom_name: classroom.classroom_name
          }
          
        })
        return studentsWithClassrooms;
    });

    const mergedArray = [];

    updatedData.forEach((classroom: any)=>{
      classroom.forEach((student: any)=>{
        if(student.classroom_name.includes("Science")){
          if(showScience)
          mergedArray.push(student);
        }
        else{
          mergedArray.push(student);
        }
      })
    })


    mergedArray.sort((a, b) => {
      if (a.student_name < b.student_name) {
        return -1;
      }
      if (a.student_name > b.student_name) {
        return 1;
      }
      return 0;
    });


    setStandardBasedDataAll(mergedArray);

   
  }
  }

  const getClassName = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );

    return info?.orderBy === "DESC"
      ? "arrow-down-classname"
      : "arrow-up-classname";
  };

  const classroomDataUpdate = (studentInfo: any) => {
    const classrooms: any = {};

    studentInfo?.performance?.tests &&
      Object.keys(studentInfo.performance.tests).forEach((item: any) => {
        studentInfo.performance.tests[item].forEach((sub: any) => {
          const key = sub.summative ? "summatives" : "formatives";
          if (!classrooms[item]) {
            classrooms[item] = { summatives: [], formatives: [] };
          }
          classrooms[item][key].push(sub);
        });
      });

    Object.keys(classrooms).forEach((cls) => {
      const sortDataSummative = sortInfo.find(
        (item: any) => item.className === cls && item.isSummative === true
      );
      const sortDataFormative = sortInfo.find(
        (item: any) => item.className === cls && item.isSummative === false
      );
      classrooms[cls].summatives = orderBy(
        classrooms[cls].summatives,
        [(sum: any) => moment(sum["date"])],
        [sortDataSummative ? sortDataSummative.orderBy.toLowerCase() : "asc"]
      );
      classrooms[cls].formatives = orderBy(
        classrooms[cls].formatives,
        [(sum: any) => moment(sum["date"])],
        [sortDataFormative ? sortDataFormative.orderBy.toLowerCase() : "asc"]
      );
    });

    return classrooms;
  };

  const setPerformanceDataWithClassesToggle = (
    classroomId: number,
    data: any,
    variants?: string[],
    scienceEnabled?:boolean
  ) => {
    
    const testData = filterAndReorderClassrooms(
      data,
      +classroomId,
      showAllClasses ? false : true
    );

    setPerformanceProgress({
      ...data,
      tests: testData,
      variants: variants,
      scienceEnabled: scienceEnabled
    });
  };

  const formatStudentName = (student_name: string) => {
    const nameArray = student_name?.split(", ");
    if (nameArray.length) {
      return `${nameArray[nameArray.length - 1]}${
        nameArray.length > 1 ? ` ${nameArray[0]}` : ""
      }`;
    }

    return "";
  };

  const getFilteredSBRData = useMemo(() => {
    let studentFilteredData = studentSBRData;

    if (!showAllClasses) {
      const classroomId =
        yearSelected === 2
          ? previousYearClassroom
          : app?.classroom?.classroomId;

      studentFilteredData = studentFilteredData
        .filter((data: any) => +data.classroom_id === +classroomId)
        .map((data: any) => {
          return {
            ...data,
            student_formatted_name: formatStudentName(data?.student_name),
          };
        });
    } else {
      if(studentData?.science_enabled){
        studentFilteredData = studentFilteredData
        .map((data: any) => {
          return {
            ...data,
            student_formatted_name: formatStudentName(data?.student_name),
          };
        });
      }else{
      studentFilteredData = studentFilteredData
        .filter((data: any) => +data.classroom_name?.indexOf("Science") === -1)
        .map((data: any) => {
          return {
            ...data,
            student_formatted_name: formatStudentName(data?.student_name),
          };
        });
      }
    }

    return studentFilteredData;
  }, [
    studentSBRData,
    showAllClasses,
    previousYearClassroom,
    isPerformance,
    app?.classroom?.classroomId,
  ]);

  const triggerUpdate = (value: any, type: number) => {
    if (!value || studentSelected <= 0) return;
    setSkeletonLoad(true);
    if (type === 1) {
      studentLastNameRef.current = classroomStudentData.find(
        (student: any) => student.id === value
      )?.first_name;
      setStudentSelected(value);
      if (isPerformance) {
        dispatch(
          getSingleStudentData({
            studentId: value,
            classroomId: app?.classroom?.classroomId,
          })
        );
      } else {
        const previousYear = yearSelected === 1 ? false : true;
        dispatch(
          getSingleStudentSBRData({
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            studentId: value,
            previousYear,
          })
        );
        dispatch(
          getSingleStudentData({
            studentId: value,
            classroomId: app?.classroom?.classroomId,
          })
        );
      }
    } else if (type === 2) {
      setYearSelected(value);
      const previousYear = value === 1 ? false : true;

      if (isPerformance) {
        if (value === 2) {
          const classroomData = classroomDataUpdate(
            studentData?.previous_year_data
          );
          setClassrooms(classroomData);
          setPerformanceDataWithClassesToggle(
            +previousYearClassroom,
            studentData?.previous_year_data?.performance
          );
        } else {
          dispatch(
            getSingleStudentData({
              studentId: studentSelected,
              classroomId: app?.classroom?.classroomId,
            })
          );
        }
      } else {
        dispatch(
          getSingleStudentSBRData({
            studentId: previousYear
              ? studentData?.student?.previous_year_id
              : studentSelected,
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            previous_year: previousYear,
          })
        );
      }
    } else if (type === 3) {
      setIsPerformance(value !== 2);
      const previousYear = yearSelected === 1 ? false : true;

      if (value === 1) {
        dispatch(
          getSingleStudentData({
            studentId: studentSelected,
            classroomId: app?.classroom?.classroomId,
          })
        );
      } else {
        dispatch(
          getSingleStudentSBRData({
            studentId: previousYear ? previousYearStudent : studentSelected,
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            previous_year: previousYear,
          })
        );
      }
    }
  };

  const getInitialStudentState = () => {
    if (location.state?.student?.id) {
      setStudentSelected(location.state.student.id);
      dispatch(
        setClassroomId(location.state.student?.classrooms[0])
      );
      
    } else if (app?.classroom?.classroomId) {
      
      dispatch(
        getStudentDataForReportCard({
          classroomId: app?.classroom?.classroomId,
        })
      );
    }
  };

  useEffect(()=>{
    if(location.state?.student?.id){
      setSkeletonLoad(true);
      dispatch(
        getStudentDataForReportCard({
          classroomId: app?.classroom?.classroomId,
        })
      );
    }
  },[])

  useEffect(() => {
    getInitialStudentState();
  }, [app?.classroom?.classroomId]);

  useEffect(() => {
    setPerformanceData(
      app?.classroom?.studentsReportData?.performance_level || {}
    );
    const mapStudents =
      app?.classroom?.studentsReportData?.mapped_students || [];
    setClassroomStudentData(mapStudents || []);
    if (mapStudents?.length > 0) {
      const firstStudentId = +mapStudents[0]?.id;
      setStudentSelected(firstStudentId);
      dispatch(
        getSingleStudentData({
          studentId: firstStudentId,
          classroomId: app?.classroom?.classroomId,
        })
      );
      dispatch(
        getStudentReportCardData({
          classroomId: app?.classroom?.classroomId,
        })
      );
    }
  }, [app.classroom.studentsReportData]);

  useEffect(() => {
    setAllStudentsPerformanceData(
      app?.classroom?.studentReport?.data?.students
    );
    setAllStudentPerformanceVariants(app?.classroom?.studentReport?.data?.variants|| []);
    setAllStudentsScienceEnabled(app?.classroom?.studentReport?.data?.science_enabled|| false);
 
    fetchAllSBRData();
  }, 
  [app.classroom.studentReport]);



  useEffect(() => {
    setPreviousYearClassroom(
      app?.classroom?.studentDetailData?.data?.previous_year_data?.performance
        ?.classroom_ids[0]?.classroom_id
    );

    setPreviousYearStudent(
      app?.classroom?.studentDetailData?.data?.previous_year_data?.student
        ?.id || 0
    );
    if (yearSelected === 1) {
      const studentDetail = app?.classroom?.studentDetailData?.data || {};
      setStudentData(studentDetail);
      console.log(studentDetail, "detail");
      if (studentDetail?.performance) {
        setPerformanceDataWithClassesToggle(
          +app?.classroom?.classroomId,
          studentDetail.performance, 
          studentDetail.variants,
          studentDetail.science_enabled
        );
      }

      const classroomData = classroomDataUpdate(studentDetail);
      setClassrooms(classroomData);
    } else {
      const previousYearData =
        app?.classroom?.studentDetailData?.data?.previous_year_data || {};
      setStudentData(previousYearData);
      setPerformanceDataWithClassesToggle(
        app?.classroom?.studentDetailData?.data?.previous_year_data?.performance
          ?.classroom_ids[0]?.classroom_id,
        previousYearData.performance, 
      );

      const classroomData = classroomDataUpdate(previousYearData);
      setClassrooms(classroomData);
    }
  }, [app.classroom.studentDetailData]);

  useEffect(() => {
    setStudentSBRData(app?.classroom?.studentSBRDetailData?.data || []);
  }, [app?.classroom?.studentSBRDetailData]);

  useEffect(() => {
    if (skeletonLoad) {
      const timer = setTimeout(() => setSkeletonLoad(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [skeletonLoad]);
 
 


  useEffect(() => {
    if (yearSelected === 1) {
      if (studentData?.performance ) {
        setPerformanceDataWithClassesToggle(
          +app.classroom.classroomId,
          studentData?.performance,
          studentData?.variants,
          studentData?.science_enabled
        );
      }
    } else {
      if (studentData?.previous_year_data) {
        setPerformanceDataWithClassesToggle(
          +previousYearClassroom,
          studentData?.previous_year_data?.performance
        );
      }
    }
  }, [showAllClasses]);

  useEffect(() => {
    const classroomData = classroomDataUpdate(studentData);
    setClassrooms(classroomData);
  }, [sortInfo]);


  useEffect(()=>{
    if(standardBasedDataAll.length && printingPdf)
    handleSBRAsync([320, 340]);
  },[standardBasedDataAll])

  const genPdf = () => {
    let name = "";
    let format = [];
    let reactElem = null;
    switch (selectedPerformance.value) {
      case "standard-based":
        // setLoadingText("Downloading");
        name = `StandardBasedReport_${
          studentLastNameRef.current || "student"
        }_${moment(new Date()).format("M/DD/YY hh:mmA")}`;
        format = [320, 340];
        reactElem = (
          <>
            {getFilteredSBRData?.map((student: any, index: number) => {
              return (
                <StandardBasedDownload
                  data={student.objectives}
                  classroom_name={student?.classroom_name}
                  lang={"en"}
                  test_name={`Student: ${student.student_name}`}
                  answerRowsHeights={100}
                  school_name={school_name}
                  student_name={student?.student_formatted_name}
                  className="download-standard-based"
                />
              );
            })}
          </>
        );
        break;
      case "performance":
        // setLoadingText("Downloading");
        name = `PerformanceReport_${studentData?.student?.first_name}_${moment(
          new Date()
        ).format("M/DD/YY hh:mmA")}`;
        format = [253, 280];
        reactElem = (
          <StudentPerformanceDownload
            classrooms={classrooms}
            performance={performanceProgress}
            studentData={studentData?.student}
            performanceHeaderData={performanceData}
            showLastThreeAttempts={showLastThreeAttempts}
            subject={app?.classroom?.subject}
            isPrev={yearSelected === 2 ? true : false}
            school_name={school_name}
            className="download-performance-report"
            isSingle= {true}
            
            
          />
        );
        break;
      default:
        break;
    }

    if (reactElem) {
      generatePDF(reactElem, format, name);
    } else {
      // setLoadingText("");
    }
  };

  const handlePerformanceAsync = (format: any, name: string) => {
    const firstRecord = allStudentsPerformanceData?.length
      ? allStudentsPerformanceData[0]
      : null;
    if (!firstRecord) {
      setLoadingText("");
      return;
    }

    const classroomData = classroomDataUpdate(firstRecord);

    const firstHtmlString = ReactDOMServer.renderToStaticMarkup(
      <StudentPerformanceDownload
        classrooms={classroomData}
        performance={firstRecord?.performance}
        studentData={firstRecord?.student}
        performanceHeaderData={performanceData}
        showLastThreeAttempts={showLastThreeAttempts}
        subject={app?.classroom?.subject}
        isPrev={yearSelected === 2 ? true : false}
        school_name={school_name}
        className="download-performance-report"
        allStdVariants = {allStudentPerformanceVariants}
        allStdScienceEnabled = {allStudentsScienceEnabled}
        isSingle= {false}

      />
    );
    const opt = {
      margin: [5, 10, 5, 10],
      filename: `PerformanceReport_${moment(new Date()).format("M/DD/YY hh:mmA")}.pdf`,
      html2canvas: { scale: 1, letterRendering: true },
      pagebreak: {mode: ['css', 'legacy'], avoid: ['tr']},
      // pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    let worker = html2pdf().set(opt).from(firstHtmlString);

    worker = worker.toPdf();

    const restRecords = allStudentsPerformanceData?.slice(1);

    // let counter=0;
    // let i = 0;
    for (let i = 0; i < restRecords.length; i++) {
      // while (i < restRecords.length) {
      const reactElem = (
        <>
          {restRecords
            ?.slice(i, i + 1)
            ?.map((allStudentPerformanceData, index) => {
              const classroomData = classroomDataUpdate(
                allStudentPerformanceData
              );

              return (
                <StudentPerformanceDownload
                  classrooms={classroomData}
                  performance={allStudentPerformanceData?.performance}
                  studentData={allStudentPerformanceData?.student}
                  performanceHeaderData={performanceData}
                  showLastThreeAttempts={showLastThreeAttempts}
                  subject={app?.classroom?.subject}
                  isPrev={yearSelected === 2 ? true : false}
                  school_name={school_name}
                  className="download-performance-report"
                  allStdVariants = {allStudentPerformanceVariants}
                  allStdScienceEnabled = {allStudentsScienceEnabled}
                  isSingle = {false}
                />
              );
            })}
        </>
      );
      const firstHtmlString = ReactDOMServer.renderToStaticMarkup(reactElem);
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();

          i === restRecords.length - 1 && setLoadingText("");
        })
        .from(firstHtmlString)
        .toContainer()
        .toCanvas()
        .toPdf();
      // i += 1;
    }

    worker.save();
  };

  const handleSBRAsync = (format) => {
    const firstSt = standardBasedDataAll[0];
    const student_formatted_name = formatStudentName(firstSt.student_name);
    const firstHtmlString = ReactDOMServer.renderToStaticMarkup(
      <StandardBasedDownload
        data={firstSt.objectives}
        classroom_name={firstSt?.classroom_name}
        lang={"en"}
        test_name={`Student: ${firstSt.student_name}`}
        answerRowsHeights={100}
        school_name={school_name}
        student_name={student_formatted_name}
        className="download-standard-based"
      />
    );
    const opt = {
      margin: [5, 10, 5, 10],
      filename: `StandardBasedReport_${moment(new Date()).format(
        "M/DD/YY hh:mmA"
      )}.pdf`,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: {mode: ['css', 'legacy'], avoid: ['tr']},
      //pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    let worker = html2pdf().set(opt).from(firstHtmlString);

    worker = worker.toPdf();

    const restRecords = standardBasedDataAll.slice(1);

    // let counter=0;
    // let i = 0;
    for (let i = 0; i < restRecords.length; i++) {
      // while (i < restRecords.length) {
      const reactElem = (
        <>
          {restRecords?.slice(i, i + 1)?.map((student) => {
            const student_formatted_name = formatStudentName(
              student.student_name
            );
            return (
              <StandardBasedDownload
                data={student.objectives}
                classroom_name={student?.classroom_name}
                lang={"en"}
                test_name={`Student: ${student.student_name}`}
                answerRowsHeights={100}
                school_name={school_name}
                student_name={student_formatted_name}
                className="download-standard-based"
              />
            );
          })}
        </>
      );
      const firstHtmlString = ReactDOMServer.renderToStaticMarkup(reactElem);
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();
          i === restRecords.length - 1 && setLoadingText("");
        })
        .from(firstHtmlString)
        .toContainer()
        .toCanvas()
        .toPdf();
      // i += 1;
    }

    worker.save();
    setPrintingPdf(false);
  };
  const genPdfAll = () => {
    switch (selectedPerformance.value) {
      case "standard-based":
        const sName = `StandardBasedReport_${
          app?.classroom?.classrooms[0]?.name
            ? app?.classroom?.classrooms[0]?.name
            : "classroom"
        }`;
        setLoadingText("Downloading");
        setPrintingPdf(true);
        printAllSBRFinal(false);
        
        break;
      case "performance":
        const pName = `PerformanceReport_${
          app?.classroom?.classrooms[0]?.name
            ? app?.classroom?.classrooms[0]?.name
            : "classroom"
        }`;
        setLoadingText("Downloading");
        handlePerformanceAsync([253, 290], pName);
        break;
      default:
        break;
    }
  };

  const generatePDF = (reactElem: any, format: any, name: string) => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(reactElem);

    const opt = {
      margin: [5, 10, 5, 10],
      filename: name,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: {mode: ['css', 'legacy'], avoid: ['tr']},
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    html2pdf()
      .from(htmlString)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        // var totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i);
        //   pdf.setFontSize(12);
        //   pdf.text(
        //     `© ${new Date().getFullYear()} Alpha Plus Educational Systems`,
        //     pdf.internal.pageSize.getWidth() - 160,
        //     pdf.internal.pageSize.getHeight() - 2
        //   );
        // }
        // setLoadingText("")
      })
      .save();
  };

  const prevYearExists = app?.classroom?.studentDetailData?.data?.previous_year_data_exists;
  return (
    <>
      <div className="reportCards hide-on-print">
        <StudentHeader
          performance={performanceData}
          students={classroomStudentData}
          triggerUpdate={triggerUpdate}
          performanceEnabled={isPerformance}
          showLastThreeAttempts={showLastThreeAttempts}
          setShowLastThreeAttempts={setShowLastThreeAttempts}
          showAllClasses={showAllClasses}
          setShowAllClasses={setShowAllClasses}
          triggerPrint={handlePrintClick}
          subject={app?.classroom?.subject}
          isPrev={yearSelected === 2 ? true : false}
          setSelectedPerformance={setSelectedPerformance}
          selectedPerformance={selectedPerformance}
          download={genPdf}
          downloadAll={genPdfAll}
          studentData={studentData}
          loadingText={loadingText}
          prevYearExists = {prevYearExists}
        />
        {isPerformance ? (
          <>
            <Performance
              performance={performanceProgress}
              classrooms={classrooms}
              handleSortClick={handleSortClick}
              getClassName={getClassName}
              skeletonLoad={skeletonLoad}
              showLastThreeAttempts={showLastThreeAttempts}
              showAllClasses={showAllClasses}
              performanceSets={performanceData}
              isPrev={yearSelected === 2 ? true : false}
            />
          </>
        ) : (
          getFilteredSBRData?.map((studentSBR: any, index: number) => {
            return (
              <StandardBasedReport
                key={index}
                isLoading={skeletonLoad}
                detailsRef={detailsRef}
                data={studentSBR}
                setDescriptionRowsHeights={setDescriptionRowsHeights}
                lang="en"
              />
            );
          })
        )}
      </div>
      {selectedPerformance?.value === "performance" && (
        <div ref={componentPerformancePrintRef} className="printData">
          <StudentPerformancePrint
            classrooms={classrooms}
            performance={performanceProgress}
            studentData={studentData?.student}
            performanceHeaderData={performanceData}
            showLastThreeAttempts={showLastThreeAttempts}
            subject={app?.classroom?.subject}
            isPrev={yearSelected === 2 ? true : false}
            school_name={school_name}
            isSingle = {true}
          />
        </div>
      )}

      {selectedPerformance?.value === "performance" &&
        allStudentsPerformanceData?.length > 0 && (
          <div className="hide-on-print">
            <div ref={componentPerformancePrintAllRef} className="printData">
              {allStudentsPerformanceData?.map(
                (allStudentPerformanceData, index) => {
                  const classroomData = classroomDataUpdate(
                    allStudentPerformanceData
                  );

                  return (
                    <div key={index}>
                      <StudentPerformancePrint
                        classrooms={classroomData}
                        performance={allStudentPerformanceData?.performance}
                        studentData={allStudentPerformanceData?.student}
                        performanceHeaderData={performanceData}
                        showLastThreeAttempts={showLastThreeAttempts}
                        subject={app?.classroom?.subject}
                        isPrev={yearSelected === 2 ? true : false}
                        school_name={school_name}
                        allStdVariants = {allStudentPerformanceVariants}
                        allStdScienceEnabled = {allStudentsScienceEnabled}
                        isSingle= {false}
                      />
                      <div style={{ breakAfter: "page" }}> </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      {selectedPerformance?.value === "standard-based" &&
        studentData?.student &&
        descriptionRowsHeights &&
        studentSBRData?.length > 0 && (
          <div ref={componentSBRPrintRef}>
            {getFilteredSBRData?.map((student: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`printData ${
                    index && "add-page-break"
                  } hide-on-print`}
                >
                  <BasedReportPrint
                    data={student.objectives}
                    classroom_name={student?.classroom_name}
                    lang={"en"}
                    test_name={`Student: ${student.student_name}`}
                    answerRowsHeights={100}
                    school_name={school_name}
                    student_name={student?.student_formatted_name}
                  />
                </div>
              );
            })}
          </div>
        )}
      {selectedPerformance?.value === "standard-based" &&
        studentData?.student &&
        descriptionRowsHeights &&
        standardBasedDataAll?.length > 0 && (
          <div className="hide-on-print">
            <div ref={componentSBRPrintAllRef}>
              {standardBasedDataAll?.map(
                (student: any, index: number) => {
                  const student_formatted_name = formatStudentName(
                    student.student_name
                  );
                  return (
                    <div
                      key={index}
                      className={`printData ${
                        index && "add-page-break"
                      } hide-on-print`}
                    >
                      <BasedReportPrint
                        data={student.objectives}
                        classroom_name={student?.classroom_name}
                        lang={"en"}
                        test_name={`Student: ${student.student_name}`}
                        answerRowsHeights={100}
                        school_name={school_name}
                        student_name={student_formatted_name}
                      />
                      <div style={{ breakAfter: "page" }}> </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default ReportCard;
